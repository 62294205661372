/* tslint:disable */
/* eslint-disable */
/**
 * A2B Customer API
 * Internal API for customer management
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@a2b-internet.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateCustomerAccountRolesRequest
 */
export interface UpdateCustomerAccountRolesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCustomerAccountRolesRequest
     */
    'roles': Array<UpdateCustomerAccountRolesRequestRolesEnum>;
}

export const UpdateCustomerAccountRolesRequestRolesEnum = {
    Admin: 'RESELLER_ADMIN',
    Finance: 'RESELLER_FINANCE',
    Order: 'RESELLER_ORDER',
    ServiceDesk: 'RESELLER_SERVICE_DESK'
} as const;

export type UpdateCustomerAccountRolesRequestRolesEnum = typeof UpdateCustomerAccountRolesRequestRolesEnum[keyof typeof UpdateCustomerAccountRolesRequestRolesEnum];


