import {type AxiosError, type AxiosResponse} from 'axios';
import {type Ref} from 'vue';
import {type ValidationError} from '../api/api';

export interface RequestOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiErrors?: Ref<ValidationError[]>;
  invalidCallback?: (response: AxiosResponse) => void | Promise<void>; // 400
  forbiddenCallback?: (response: AxiosResponse) => void | Promise<void>; // 403
  notFoundCallback?: (response: AxiosResponse) => void | Promise<void>; // 404
  conflictCallback?: (response: AxiosResponse) => void | Promise<void>; // 409
  tooManyRequestsCallback?: (response: AxiosResponse) => void | Promise<void>; // 429
  anyErrorCallback?: (response: AxiosResponse) => void | Promise<void>; // Any non 200 code
  finallyCallback?: () => void | Promise<void>;
}

export async function getValidResponse(
  apiCall: () => Promise<AxiosResponse<unknown>>,
  options?: RequestOptions,
): Promise<boolean> {
  try {
    if (options?.apiErrors) {
      options.apiErrors.value = [];
    }

    await apiCall();

    // Result is good, otherwise an exception would have been thrown
    return true;
  } catch (e) {
    await handleException(e as AxiosError, options);
    return false;
  } finally {
    if (options?.finallyCallback) {
      await options.finallyCallback();
    }
  }
}

export async function getResponseObject<T>(
  apiCall: () => Promise<AxiosResponse<T>>,
  options?: RequestOptions,
): Promise<T | undefined> {
  try {
    if (options?.apiErrors) {
      options.apiErrors.value = [];
    }

    return (await apiCall()).data;
  } catch (e) {
    await handleException(e as AxiosError, options);
  } finally {
    if (options?.finallyCallback) {
      await options.finallyCallback();
    }
  }
}

async function handleException(e: AxiosError, options?: RequestOptions) {
  if (e.response) {
    if (e.response.status === 400 && (options?.apiErrors || options?.invalidCallback)) {
      if (options?.apiErrors) {
        options.apiErrors.value = (e.response.data as {validationErrors: ValidationError[]}).validationErrors;
      }

      if (options?.invalidCallback) {
        await options.invalidCallback(e.response);
      }

      return;
    }

    if (e.response.status === 403 && options?.forbiddenCallback) {
      await options.forbiddenCallback(e.response);

      return;
    }

    if (e.response.status === 404 && options?.notFoundCallback) {
      await options.notFoundCallback(e.response);

      return;
    }

    if (e.response.status === 409 && options?.conflictCallback) {
      await options.conflictCallback(e.response);

      return;
    }

    if (e.response.status === 429 && options?.tooManyRequestsCallback) {
      await options.tooManyRequestsCallback(e.response);

      return;
    }

    // 500 response is handled globally
    if (e.response.status !== 500 && options?.anyErrorCallback) {
      await options.anyErrorCallback(e.response);

      return;
    }
  }

  throw e;
}
