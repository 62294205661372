<template>
  <div>
    <div
      v-if="account !== null"
      class="wide-container">
      <PageTitle
        :props="{company: account.company}"
        title="dashboard.welcome"/>

      <p>
        <Translated msg="dashboard.welcome-text"/>
      </p>

      <LoyaltyPage v-if="loyaltyParticipant"/>
    </div>
    <div
      v-else
      class="small-container">
      <DataCard
        class="mb-2"
        header-class="bg-theme">
        <template #header>
          <FlexTextIcon icon="key">
            <Translated msg="auth.login"/>
          </FlexTextIcon>
        </template>

        <p>
          <Translated msg="auth.login-text"/>&nbsp;<Translated
            msg="auth.login-text-sso"
            :props="{company}"/>
        </p>

        <div class="d-flex justify-content-end">
          <LoadButton
            icon="sign-in-alt"
            label="auth.login-sso"
            :label-props="{company}"
            :loading="authState.loggingIn"
            type="button"
            @click="onSsoAuth"/>
        </div>
      </DataCard>

      <DataCard
        class="mb-2"
        header-class="bg-theme">
        <template #header>
          <FlexTextIcon icon="signature">
            <Translated msg="apply.title.short"/>
          </FlexTextIcon>
        </template>

        <p>
          <Translated msg="apply.text.partner"/>
        </p>

        <div class="d-flex justify-content-end">
          <router-link
            class="btn btn-primary"
            :to="{name: 'apply'}">
            <SimpleIcon icon="hand-point-right"/>
            <Translated msg="apply.button.partner"/>
          </router-link>
        </div>
      </DataCard>
    </div>
  </div>
</template>

<script lang="ts">
import LoyaltyPage from '@/pages/account/LoyaltyPage.vue';
import {companyName} from '@/utils';
import FlexTextIcon from '@components/FlexTextIcon.vue';
import LoadButton from '@components/form/LoadButton.vue';
import DataCard from '@components/layout/DataCard.vue';
import PageTitle from '@components/layout/PageTitle.vue';
import SimpleIcon from '@components/SimpleIcon.vue';
import {useApiErrors} from '@plugins/api/api';
import {useAuthenticator, useCurrentAccount} from '@plugins/auth/auth';
import Translated from '@plugins/i18n/components/Translated.vue';
import {defineComponent, reactive} from 'vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    DataCard,
    LoyaltyPage,
    LoadButton,
    SimpleIcon,
    FlexTextIcon,
    Translated,
    PageTitle,
  },
  setup() {
    const authenticator = useAuthenticator();
    const account = useCurrentAccount();
    const authApiErrors = useApiErrors();

    const authState = reactive({
      loggingIn: false,
      authFailure: false,
    });

    return {
      account,
      authApiErrors,
      authState,
      onSsoAuth: async () => {
        authState.loggingIn = true;
        try {
          window.location.href = await authenticator.generateSsoAuthenticationRedirect();
        } catch {
          authState.authFailure = true;
          authState.loggingIn = false;
        }
      },
      loyaltyParticipant: authenticator.isLoyaltyParticipant,
      company: companyName(),
    };
  },
});
</script>
